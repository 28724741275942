/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, YouTube } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"fotobazen"}>
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"xvmo0rfy4v"} style={{"paddingTop":15,"paddingBottom":15.625}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box ff--3" style={{"marginTop":0,"paddingTop":0}} content={"Kurzy / bazény"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"7bejagl5we7"} style={{"paddingTop":50,"paddingBottom":21}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/15c162302f0a4a64b7f7748674a77960_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/15c162302f0a4a64b7f7748674a77960_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/15c162302f0a4a64b7f7748674a77960_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/15c162302f0a4a64b7f7748674a77960_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/15c162302f0a4a64b7f7748674a77960_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/15c162302f0a4a64b7f7748674a77960_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/52806dd0c0b1489eb00e476080c6ff59_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/52806dd0c0b1489eb00e476080c6ff59_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/52806dd0c0b1489eb00e476080c6ff59_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/52806dd0c0b1489eb00e476080c6ff59_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/4444170083e84f51830b95d2f385ee7d_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/4444170083e84f51830b95d2f385ee7d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/4444170083e84f51830b95d2f385ee7d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/4444170083e84f51830b95d2f385ee7d_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/fa16b45e3c634c278cf2ab870ed41632_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/fa16b45e3c634c278cf2ab870ed41632_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/fa16b45e3c634c278cf2ab870ed41632_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/fa16b45e3c634c278cf2ab870ed41632_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/fa16b45e3c634c278cf2ab870ed41632_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/fa16b45e3c634c278cf2ab870ed41632_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":15.71875}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/02712ec1b78c4443a816a2d89f1d1089_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/02712ec1b78c4443a816a2d89f1d1089_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/02712ec1b78c4443a816a2d89f1d1089_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/02712ec1b78c4443a816a2d89f1d1089_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/84b1191300614991a099c1ece34b838e_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/84b1191300614991a099c1ece34b838e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/84b1191300614991a099c1ece34b838e_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/b3c690d6762340228d8b4fd67508bbd2_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/b3c690d6762340228d8b4fd67508bbd2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/b3c690d6762340228d8b4fd67508bbd2_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/873aeda212c24f299a60eec2289cf7ee_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/873aeda212c24f299a60eec2289cf7ee_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/873aeda212c24f299a60eec2289cf7ee_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":14.6953125,"paddingTop":0}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/8cc84be70cac458f839dfaa31402e5dc_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/8cc84be70cac458f839dfaa31402e5dc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/8cc84be70cac458f839dfaa31402e5dc_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/23981bf78fd94f0fafa71b5ea781ba08_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/23981bf78fd94f0fafa71b5ea781ba08_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/23981bf78fd94f0fafa71b5ea781ba08_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/664383b3ffe8427b928019c044956d0c_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/664383b3ffe8427b928019c044956d0c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/664383b3ffe8427b928019c044956d0c_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/f4770fb97e4c475584d6135044803362_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/f4770fb97e4c475584d6135044803362_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/f4770fb97e4c475584d6135044803362_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":14.6953125,"paddingTop":0}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/d550b8a4c7f54599b79525e130ea4444_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/d550b8a4c7f54599b79525e130ea4444_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d550b8a4c7f54599b79525e130ea4444_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d550b8a4c7f54599b79525e130ea4444_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/6bf2bed8af3f469e888df69ffd688946_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/6bf2bed8af3f469e888df69ffd688946_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/6bf2bed8af3f469e888df69ffd688946_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/6bf2bed8af3f469e888df69ffd688946_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/aea5ee2089d1412ba77f021ff3cd359f_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/aea5ee2089d1412ba77f021ff3cd359f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/aea5ee2089d1412ba77f021ff3cd359f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/aea5ee2089d1412ba77f021ff3cd359f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/aea5ee2089d1412ba77f021ff3cd359f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/aea5ee2089d1412ba77f021ff3cd359f_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/69df9530e9cc4397826509669c7e00fe_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/69df9530e9cc4397826509669c7e00fe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/69df9530e9cc4397826509669c7e00fe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/69df9530e9cc4397826509669c7e00fe_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"ubminz2amjo"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"maxWidth":832,"paddingLeft":14}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--26" style={{"paddingLeft":0}} content={"Jak na vypouštění <br>dekompresní bojky<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"GOGYiDcTT-s"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}